import { Link } from 'react-router-dom';
import SEOScript from './SEOScript';

const Homepage = () => {
  // check the url query parameter utm_source
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get('utm_source');
  if (utmSource) {
    localStorage.setItem('signup_source', utmSource);
  }

  return (
    <div className='bg-white min-h-screen max-w-[1512px] mx-auto text-left text-5xl text-black font-montserrat flex flex-col justify-center items-center p-20 pt-28 gap-20 text-not-black'>
      <SEOScript></SEOScript>
      <div
        className='text-center flex flex-col gap-4 items-center rounded-lg bg-cover justify-center relative shadow-md w-full h-fit pb-56 pt-48'
        style={{ backgroundImage: 'url(./dots.png)' }}
      >
        <img
          src='/iPhone15.png'
          alt='iPhone'
          className='w-5/12 absolute top-0 bottom-0 my-auto left-0'
        />
        <img
          src='/chatbot.png'
          alt='Chatbot'
          className='w-1/5 absolute right-0 top-2'
        />
        <img
          src='/folder.png'
          alt='Folder'
          className='w-1/4 absolute bottom-0 right-40'
        />
        <img src='/logo.svg' alt='Logo' className='w-20' />
        <div className='text-[3rem] font-bold'>Every conversation,</div>
        <div className='text-[3rem] text-gray-500 font-bold'>
          Perfectly captured
        </div>
        <div className='text-lg text-center leading-relaxed'>
          Elevate your communication with real-time transcription,
          <br /> enhance your conversations with precision and clarity
        </div>
        <div className='flex justify-between w-fit mx-auto items-center gap-4'>
          <Link
            to='/signup'
            className='z-10 bg-blue-500 text-xl text-white rounded-full py-2 px-5 no-underline hover:bg-blue-600 transition-colors duration-200'
          >
            Sign up for Free
          </Link>
        </div>
      </div>
      <div className='flex items-center justify-center gap-6 w-fit mx-auto'>
        <div className='text-2xl'>Integrated with: </div>
        <img src='/zoom.png' alt='Zoom' className='w-28' />
        <img src='/teams.png' alt='Teams' className='w-46' />
        <img src='/slack.png' alt='Slack' className='w-32' />
        <img src='/gmeet.png' alt='Google Meet' className='w-22' />
      </div>
      <div className='w-full text-left flex flex-col justify-center gap-6 pl-20'>
        <div className='text-base'>OUR MISSION</div>
        <div className='text-3xl font-bold w-4/6'>
          Interpret AI helps break down communication barriers.
          <br /> <br /> Our platform empowers teams to capture and translate
          conversations in real-time.
        </div>
      </div>
      <div
        style={{ backgroundImage: 'url(./stripes.png)' }}
        className='rounded-lg shadow-md h-fit bg-white w-full text-center flex flex-col justify-center items-center gap-6 px-2 py-20'
      >
        <div className='text-4xl font-bold w-fit flex gap-4 items-center'>
          Introducing,{' '}
          <div className='font-bold text-dodgerblue-200 flex gap-4 items-center w-full'>
            interpret
            <img src='/logo.svg' alt='Logo' className='w-14 self-start mb-6' />
          </div>
        </div>
        <div className='flex flex-col items-center gap-6 mb-6'>
          <div className='text-3xl font-semibold'>created by BlueJay AI</div>
          <div className='font-thin text-lg text-updated-color-grey2 leading-7'>
            Enabling individuals facing language barriers to enjoy
            <br /> seamless conversations with an AI-powered transcription tool.
          </div>
        </div>
        <div className='flex justify-between w-full gap-14'>
          <div
            className='flex flex-col shadow-md text-left gap-4 p-2 w-full bg-[#DBDAF8] rounded-lg p-4 px-6 pb-10 pr-16 bg-no-repeat bg-cover'
            style={{ backgroundImage: 'url(./screens.png)' }}
          >
            <div className='text-base'>REAL-TIME SPEECH TRANSCRIBING</div>
            <div className='font-bold leading-relaxed w-3/4 rounded-lg p-2'>
              Capture and understand every conversation with precise, real-time
              transcription
            </div>
            <img
              src='/example.svg'
              alt='Example'
              className='w-64 h-auto self-end'
            />
          </div>
          <div className='flex flex-col border border-solid border-[#dadada] shadow-md p-4 pb-14 text-left gap-4 w-full bg-blue-200 rounded-lg px-6'>
            <div className='text-base'>MEETING ROOM ADD-ON</div>
            <div className='font-bold leading-relaxed w-3/4 mb-4'>
              Receive live subtitles for all your meetings, making it easier to
              follow along and stay engaged
            </div>
            <div className='flex gap-4 justify-center my-auto'>
              <img src='/integrations.svg' className='flex-shrink' />
              <img src='/popups.svg' className='flex-shrink' />
            </div>
          </div>
        </div>
        <div className='w-12/12 rounded-lg shadow-md p-6 flex bg-white px-8 pr-16'>
          <div className='flex flex-col text-left gap-4'>
            <div className='text-base'>LIVE BROADCAST ROOM SHARING</div>
            <div className='font-bold w-3/4'>
              Join live events remotely and read the speech in real-time,
              translated into your preferred language
            </div>
            <img src='/webglobe.png' alt='globe' className='w-10/12' />
          </div>
          <img src='/pins.svg' alt='Pins' className='w-full self-center' />
        </div>
        <div className='flex w-full gap-10'>
          <div className='flex flex-col w-full gap-6'>
            <div className='flex flex-col gap-4 text-left bg-neutral-400 rounded-lg p-8 shadow-md'>
              <div className='text-base'>ACCURATE AI ASSISTANCE</div>
              <div className='font-bold w-fit'>
                Benefit from advanced AI for highly accurate transcription and
                translation results
              </div>
            </div>
            <div className='flex flex-col gap-4 h-full text-left bg-[#EFEFEF] rounded-lg p-8 shadow-md'>
              <div className='text-base'>AUDIO TRANSCRIPTION & TRANSLATION</div>
              <div className='font-bold w-fit'>
                Capture speech instantly and translate it seamlessly into your
                language of choice — quick, precise, and effortless
                communication at your fingertips
              </div>
              <img
                src='/docs.svg'
                alt='Docs'
                className='w-11/12 self-center mt-auto xl:my-auto'
              />
            </div>
          </div>
          <div className='flex flex-col gap-4 text-left bg-[#bad9b0] rounded-lg p-8 py-12 shadow-md'>
            <div className='text-base'>EASY DOCUMENT MANAGEMENT</div>
            <div className='font-bold w-3/4'>
              Store, organize, and access all your transcribed and translated
              documents in one convenient location
            </div>
            <img
              src='/mobilepics.svg'
              alt='mobile pics'
              className='w-9/12 self-center mt-4'
            />
          </div>
        </div>
      </div>
      <a
        href='https://theresanaiforthat.com/ai/interpret-ai/?ref=featured&v=3528940'
        target='_blank'
        rel='nofollow'
      >
        <img
          width='300'
          src='https://media.theresanaiforthat.com/featured-on-taaft.png?width=600'
        />
      </a>
    </div>
  );
};

export default Homepage;
