import { isMobile } from 'react-device-detect';

export const Switch = ({ isOn, handleToggle, onColor, text, loading }) => {
  const mobileStyles = {
    switchCircle: `absolute text-2xs flex items-center justify-center left-0.5 top-[1.5px] bg-white w-fit p-0.5 h-4 font-medium rounded-full transition-transform duration-300 ease-in-out ${
      isOn ? 'text-dodgerblue-200 transform translate-x-full' : 'text-gray-600'
    }`,
    switchBackground: `block w-11 pr-0.5 h-5 rounded-full border-solid border-1 border-white ${
      isOn ? onColor : 'bg-gray-600'
    } transition-colors duration-300 ease-in-out`,
  };

  const desktopStyles = {
    switchCircle: `absolute text-2xs flex items-center justify-center left-0 top-0 bottom-0 my-auto bg-white w-7 h-7 font-medium rounded-full transition-transform duration-300 ease-in-out ${
      isOn ? 'text-dodgerblue-200 transform translate-x-full' : 'text-gray-600'
    }`,
    switchBackground: `w-14 h-7 rounded-full ${
      isOn ? onColor : 'bg-gray-600'
    } transition-colors duration-300 ease-in-out`,
  };

  return (
    <label className='flex items-center cursor-pointer'>
      <div className='relative'>
        <input
          type='checkbox'
          className='sr-only'
          checked={isOn}
          onChange={handleToggle}
          disabled={loading}
        />
        <div
          className={
            !isMobile
              ? desktopStyles.switchBackground
              : mobileStyles.switchBackground
          }
        ></div>
        <div
          className={
            !isMobile ? desktopStyles.switchCircle : mobileStyles.switchCircle
          }
        >
          {isOn ? 'On' : 'Off'}
        </div>
      </div>
      <div
        className={`text-gray-700 ${(!isMobile && 'ml-3 font-medium') || 'text-[12px] ml-1'}`}
      >
        {text}
      </div>
    </label>
  );
};
