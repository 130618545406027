import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWhisper } from '@chengsokdara/use-whisper';
import { socket } from '../../socketContext';
import { languageToCode, top100WorldLanguages } from '../../languages';
import {
  jwtTokenRef,
  serverURL,
  translateTexts,
  userInfoRef,
} from '../../httpContext';
import { format } from 'date-fns';
import axios from 'axios';
import RecentsSidebar from './RecentsSidebar';
import { mdiArrowDown } from '@mdi/js';
import Icon from '@mdi/react';
import StartJoinRoom from '../../components/StartJoinRoom';
import { OutOfCreditsWindow } from '../../components/OutOfCreditsWindow';
import ChatbotWidget from './ChatbotWidget';

const formatTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const seconds = totalSeconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const Transcribe = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [elapsedTime, setElapsedTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const messagesContainerRef = useRef(null);
  const correctedMessagesContainerRef = useRef(null);
  const shouldScroll = useRef(false);
  const correctedMessagesShouldScroll = useRef(false);
  const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
  const [languageTwoDropdownVisible, setLanguageTwoDropdownVisible] =
    useState(false);
  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
  const [
    correctedMessagesShouldShowScrollButton,
    setCorrectedMessagesShouldShowScrollButton,
  ] = useState(false);
  const [showStartJoinARoomOverlay, setShowStartJoinARoomOverlay] =
    useState(false);
  const [hoveringEnterBroadcast, setHoveringEnterBroadcast] = useState(false);

  const [isLoadingTranslation, setIsLoadingTranslation] = useState(false);
  const [isLoadingOutputTranslation, setIsLoadingOutputTranslation] =
    useState(false);
  const inputLanguage = useRef('Original');
  const [inputLanguageDisplay, setInputLanguageDisplay] =
    useState('Detect Language');
  const outputLanguage = useRef('Original');
  const [outputLanguageDisplay, setOutputLanguageDisplay] =
    useState('Original');

  const [searchBarInput, setSearchBarInput] = useState(null);
  const [searchBarTwoInput, setSearchBarTwoInput] = useState(null);
  const inputLanguageRef = useRef(null);
  const outputLanguageRef = useRef(null);
  const inputLanguageDropdownRef = useRef(null);
  const outputLanguageDropdownRef = useRef(null);

  const usingInputLanguagePopupRef = useRef(false);
  const usingOutputLanguagePopupRef = useRef(false);

  const stoppedTranscribing = useRef(false);

  const isBroadcastingRef = useRef(false);
  const isInBroadcastRoomRef = useRef(false);

  const initMessages = [];
  const [messages, setMessages] = useState(initMessages);
  const [messagesMerged, setMessagesMerged] = useState(initMessages);
  const [isOutOfCreditsOpen, setIsOutOfCreditsOpen] = useState(false);
  const messagesRef = useRef(messages);
  const [isTranscribing, setTranscribing] = useState(false);
  const isTranscribingRef = useRef(isTranscribing);
  const playingAudioRef = useRef(false);
  const roomIdRef = useRef(null);
  const [isChatbotOpen, setIsChatbotOpen] = useState(true);
  const isChatbotOpenRef = useRef(isChatbotOpen);
  const chatbotContextRef = useRef(null);

  // on page load, set the room id to the query parameter
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const roomId = queryParams.get('room_id');
    if (roomId) {
      roomIdRef.current = roomId;
    }
  }, []);

  useEffect(() => {
    isChatbotOpenRef.current = isChatbotOpen;
  }, [isChatbotOpen]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log('loading zoom oauth callback');
    const code = queryParams.get('code');
    if (code) {
      const redirectUri = window.location.origin + window.location.pathname;
      fetch(`${serverURL}/oauth-callback/zoom`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtTokenRef.current,
        },
        body: JSON.stringify({ code, redirect_uri: redirectUri }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Success:', data);
          // setHasZoomIntegration(true);
          // Handle success (e.g., redirect or update state)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  useEffect(() => {
    isTranscribingRef.current = isTranscribing;
  }, [isTranscribing]);

  // useEffect(() => {
  //   if (userInfoRef.current == undefined) {
  //     navigate('/login');
  //   }
  // }, []);

  const startTimer = () => {
    setStartTime(new Date().getTime());
    setTimerRunning(true);
  };

  const pauseTimer = () => {
    setTimerRunning(false);
  };

  let audioRef = useRef(null);

  const updateMessagesMerged = (messagesUpdated) => {
    // copy the messagesUpdated array to avoid reference issues
    var messagesUpdatedCopy = JSON.parse(JSON.stringify(messagesUpdated));
    var messagesMergedUpdated = [];
    var startOfMessagesToKeep = -1;
    for (var i = 0; i < messagesUpdated.length; i++) {
      if (messagesUpdated[i]['transcript'] != '') {
        const currentTimestamp = messagesUpdated[i]['timestamp'];
        const lastMergedMessage =
          messagesMergedUpdated[messagesMergedUpdated.length - 1];

        if (
          i > 0 &&
          !messagesUpdated[i]['did_speaker_change'] &&
          !messagesUpdated[i]['is_ai_answer'] &&
          !messagesUpdated[i - 1]['is_ai_answer'] &&
          messagesMergedUpdated.length > 0 &&
          lastMergedMessage['messages_merged'] < 4 &&
          currentTimestamp - lastMergedMessage['timestamp'] <= 60 // Check if the time gap is less than or equal to 7 seconds
        ) {
          lastMergedMessage['transcript'] =
            lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

          lastMergedMessage['messages_merged']++;

          for (var key in messagesUpdated[i]['corrected_transcripts']) {
            if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
              lastMergedMessage['corrected_transcripts'][key] = '';
            }
            lastMergedMessage['corrected_transcripts'][key] =
              lastMergedMessage['corrected_transcripts'][key] +
              ' ' +
              messagesUpdated[i]['corrected_transcripts'][key];
          }
        } else {
          if (messagesUpdatedCopy.length > 10 && startOfMessagesToKeep == -1) {
            startOfMessagesToKeep = i;
          }
          messagesUpdated[i]['messages_merged'] = 1;
          const timestamp = new Date(currentTimestamp * 1000);
          messagesUpdated[i]['timestamp_display'] =
            timestamp.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });
          messagesMergedUpdated.push(messagesUpdated[i]);
        }
      }
    }

    if (messagesMergedUpdated.length > 0) {
      var lastMessageMerged =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];
      lastMessageMerged['unconfirmed_transcript'] = '';
      lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
    }

    if (
      messagesUpdatedCopy.length > 0 &&
      !messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['is_final']
    ) {
      var lastMessageMerged =
        messagesMergedUpdated[messagesMergedUpdated.length - 1];
      var lengthOfLastMessage =
        messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript']
          .length;
      lastMessageMerged['transcript'] = messagesMergedUpdated[
        messagesMergedUpdated.length - 1
      ]['transcript'].slice(0, -lengthOfLastMessage);
      lastMessageMerged['unconfirmed_transcript'] =
        messagesUpdatedCopy[messagesUpdatedCopy.length - 1]['transcript'];
      lastMessageMerged['unconfirmed_corrected_transcripts'] = {};
      for (var key in lastMessageMerged['corrected_transcripts']) {
        lengthOfLastMessage =
          messagesUpdatedCopy[messagesUpdatedCopy.length - 1][
            'corrected_transcripts'
          ][key].length;
        lastMessageMerged['corrected_transcripts'][key] = lastMessageMerged[
          'corrected_transcripts'
        ][key].slice(0, -lengthOfLastMessage);
        lastMessageMerged['unconfirmed_corrected_transcripts'][key] =
          messagesUpdatedCopy[messagesUpdatedCopy.length - 1][
            'corrected_transcripts'
          ][key];
      }
    }

    // ensure only last 30 messages are stored
    if (messagesMergedUpdated.length > 15) {
      messagesMergedUpdated = messagesMergedUpdated.slice(
        messagesMergedUpdated.length - 15,
      );
      console.log('startOfMessagesToKeep: ' + startOfMessagesToKeep);

      setMessages(messagesUpdatedCopy.slice(startOfMessagesToKeep));
      messagesRef.current = messagesUpdatedCopy.slice(startOfMessagesToKeep);
    } else {
      setMessages(messagesUpdatedCopy);
      messagesRef.current = messagesUpdatedCopy;
    }

    console.log(messagesMergedUpdated);
    console.log(
      'messagesMergedUpdated: ' + JSON.stringify(messagesMergedUpdated),
    );
    setMessagesMerged(messagesMergedUpdated);
  };

  useEffect(() => {
    // click outside the language picker popup to close it
    const handleClickOutside = (event) => {
      if (usingInputLanguagePopupRef.current) {
        if (
          inputLanguageRef.current &&
          !inputLanguageRef.current.contains(event.target)
        ) {
          if (
            inputLanguageDropdownRef.current &&
            !inputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingInputLanguagePopupRef.current = false;
            setLanguageDropdownVisible(false);
          }
        }
      }
      if (usingOutputLanguagePopupRef.current) {
        if (
          outputLanguageRef.current &&
          !outputLanguageRef.current.contains(event.target)
        ) {
          if (
            outputLanguageDropdownRef.current &&
            !outputLanguageDropdownRef.current.contains(event.target)
          ) {
            usingOutputLanguagePopupRef.current = false;
            setLanguageTwoDropdownVisible(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    socket.removeAllListeners();
    console.log('adding listener for receive_transcriptions');
    socket.on('room', (data) => {
      isBroadcastingRef.current = true;
      isInBroadcastRoomRef.current = true;
      console.log('joined room ' + data);
    });

    if (roomIdRef.current) {
      console.log('joining room ' + roomIdRef.current);
    }

    socket.on('receive_transcriptions', async (data) => {
      console.log('received transcriptions ' + JSON.stringify(data));
      let messagesToAdd = [];
      let oldMessages = [...messagesRef.current];
      let message = data;

      let messageToAdd = {
        transcript_id: message['transcript_id'],
        transcript: message['transcript'],
        corrected_transcripts: message['corrected_transcripts'],
        is_final: message['is_final'],
        timestamp: message['timestamp'],
        diarization: message['diarization'],
        did_speaker_change: message['did_speaker_change'],
        is_ai_answer: message['is_ai_answer'],
      };

      let replacedMessage = false;
      for (let i = 0; i < oldMessages.length; i++) {
        if (oldMessages[i]['transcript_id'] == message['transcript_id']) {
          oldMessages[i] = messageToAdd;
          replacedMessage = true;
          messagesToAdd = [];
        }
      }

      if (!replacedMessage) {
        messagesToAdd = [message];
      }

      const messagesContainer = messagesContainerRef.current;
      if (messagesContainer) {
        const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
        console.log(scrollTop, clientHeight, scrollHeight);
        console.log('dist: ', scrollHeight - clientHeight - scrollTop);
        const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
        if (atBottom) {
          shouldScroll.current = true;
        } else {
          setShouldShowScrollButton(true);
        }
      }
      const correctedMessagesContainer = correctedMessagesContainerRef.current;
      if (correctedMessagesContainer) {
        const { scrollTop, clientHeight, scrollHeight } =
          correctedMessagesContainer;
        console.log(scrollTop, clientHeight, scrollHeight);
        console.log('dist: ', scrollHeight - clientHeight - scrollTop);
        const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
        if (atBottom) {
          correctedMessagesShouldScroll.current = true;
        } else {
          setCorrectedMessagesShouldShowScrollButton(true);
        }
      }

      setMessages([...oldMessages, ...messagesToAdd]);
      let messagesUpdated = [];
      for (let i = 0; i < oldMessages.length; i++) {
        messagesUpdated = [
          ...messagesUpdated,
          JSON.parse(JSON.stringify(oldMessages[i])),
        ];
      }
      for (let i = 0; i < messagesToAdd.length; i++) {
        messagesUpdated = [
          ...messagesUpdated,
          JSON.parse(JSON.stringify(messagesToAdd[i])),
        ];
      }
      console.log('messages updated: ' + JSON.stringify(messagesUpdated));

      var completeTranscript = '';
      for (var i = 0; i < messagesUpdated.length; i++) {
        completeTranscript += messagesUpdated[i]['transcript'];
      }

      chatbotContextRef.current = completeTranscript;

      updateMessagesMerged(messagesUpdated);

      messagesRef.current = [...oldMessages, ...messagesToAdd];
      // localStorage.setItem('messages', JSON.stringify(messagesUpdated));
    });

    return () => {
      console.log('Removing socket listeners');
      socket.removeAllListeners();
      socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
      const expiryDateEpochSeconds =
        Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60; // 30 days later in epoch seconds
      socket.emit('save_audio', {
        jwt_token: jwtTokenRef.current,
        clear_audio: true,
        expiry_date_epoch_seconds: expiryDateEpochSeconds,
      });
    };
  }, [pathname]);

  const onTranscribe = async (blob) => {
    if (isTranscribingRef.current) {
      if (userInfoRef.current?.credits <= 10) {
        handleTranscribeButton();
        setTranscribing(false);
        setIsOutOfCreditsOpen(true);
        return {
          blob,
          text: '',
        };
      }
    }

    if (stoppedTranscribing.current) {
      clearChunks();
      stoppedTranscribing.current = false;
      return {
        blob,
        text: '',
      };
    }

    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });

    if (jwtTokenRef.current == undefined) {
      return;
    }

    var translateToLanguages = [];
    var possibleLanguages = [];

    if (
      inputLanguage.current != 'Detect Language' &&
      inputLanguage.current != 'Original'
    ) {
      translateToLanguages.push(inputLanguage.current);
      if (languageToCode[inputLanguage.current] != undefined) {
        possibleLanguages.push(languageToCode[inputLanguage.current]);
      }
    }

    if (
      outputLanguage.current != 'Detect Language' &&
      outputLanguage.current != 'Original'
    ) {
      translateToLanguages.push(outputLanguage.current);
      if (languageToCode[outputLanguage.current] != undefined) {
        possibleLanguages.push(languageToCode[outputLanguage.current]);
      }
    }

    if (possibleLanguages.length > 0) {
      socket.emit('possible_languages', [
        languageToCode[inputLanguage.current],
        languageToCode[outputLanguage.current],
      ]);
    }

    socket.emit('transcribe', {
      audio: base64,
      jwt_token: jwtTokenRef.current,
      translate_to_languages: translateToLanguages,
    });
    clearChunks();

    return {
      blob,
      text: '',
    };
  };

  const { transcript, recording, stopRecording, startRecording, clearChunks } =
    useWhisper({
      onDataAvailable: onTranscribe,
      onTranscribe: onTranscribe,
      streaming: true,
      timeSlice: 250,
      removeSilence: false,
      whisperConfig: {
        language: 'en',
      },
    });

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (messagesContainer) {
      let lastScrollTop = messagesContainer.scrollTop;

      const handleScroll = () => {
        const currentScrollTop = messagesContainer.scrollTop;
        if (currentScrollTop < lastScrollTop) {
          shouldScroll.current = false;
        }
        lastScrollTop = currentScrollTop;
      };

      if (messagesContainer) {
        messagesContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (messagesContainer) {
          messagesContainer.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, []);

  useEffect(() => {
    const correctedMessagesContainer = correctedMessagesContainerRef.current;
    if (correctedMessagesContainer) {
      let lastScrollTop = correctedMessagesContainer.scrollTop;

      const handleScroll = () => {
        const currentScrollTop = correctedMessagesContainer.scrollTop;
        if (currentScrollTop < lastScrollTop) {
          correctedMessagesShouldScroll.current = false;
        }
        lastScrollTop = currentScrollTop;
      };

      if (correctedMessagesContainer) {
        correctedMessagesContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (correctedMessagesContainer) {
          correctedMessagesContainer.removeEventListener(
            'scroll',
            handleScroll,
          );
        }
      };
    }
  }, []);

  const scrollToBottom = () => {
    setShouldShowScrollButton(false);
    messagesContainerRef.current?.scrollTo({
      top: Math.ceil(messagesContainerRef.current.scrollHeight),
      behavior: 'smooth',
    });
  };

  const correctedScrollToBottom = () => {
    setCorrectedMessagesShouldShowScrollButton(false);
    correctedMessagesContainerRef.current?.scrollTo({
      top: Math.ceil(correctedMessagesContainerRef.current.scrollHeight),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (correctedMessagesShouldScroll.current) correctedScrollToBottom();
    if (shouldScroll.current) scrollToBottom();
  }, [messagesMerged]);

  const handleInputLanguageClick = (language) => {
    if (language !== inputLanguage.current) {
      if (messagesRef.current.length) setIsLoadingTranslation(true);

      const textsToTranslate = [];
      const messagesToTranslateIndices = [];

      // Collect texts to translate and their indices
      messagesRef.current.forEach((message, index) => {
        if (message['corrected_transcripts'][language] === undefined) {
          textsToTranslate.push(message.transcript);
          messagesToTranslateIndices.push(index);
        }
      });

      // Proceed only if there are texts to translate
      if (textsToTranslate.length > 0) {
        translateTexts(textsToTranslate, language)
          .then((translations) => {
            const updatedMessages = [...messages]; // Create a shallow copy of messages

            // Assign translations to the correct messages
            messagesToTranslateIndices.forEach(
              (messageIndex, translationIndex) => {
                const translatedText = translations[translationIndex];
                // Ensure 'corrected_transcripts' exists
                if (!updatedMessages[messageIndex]['corrected_transcripts']) {
                  updatedMessages[messageIndex]['corrected_transcripts'] = {};
                }
                updatedMessages[messageIndex]['corrected_transcripts'][
                  language
                ] = translatedText.trim();
              },
            );
            setMessages(updatedMessages);
            updateMessagesMerged(updatedMessages);
          })
          .catch((error) => {
            console.error('Translation failed:', error);
            // Handle translation errors if necessary
          });
      } else {
        // If all messages are already translated, you might want to update the display immediately
        updateMessagesMerged(messages);
      }
    }

    // Update the language state regardless of whether translation was needed
    inputLanguage.current = language;
    setInputLanguageDisplay(language);
    setLanguageDropdownVisible(false);
    setTimeout(() => {
      setIsLoadingTranslation(false);
    }, 1000);
  };

  useEffect(() => {
    let interval;
    if (timerRunning) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        const elapsed = now - startTime;
        // console.log('elapsed: ' + formatTime(elapsed))
        setElapsedTime(elapsed);
        // console.log('elapsedTime: ' + elapsedTime)
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerRunning, startTime]);

  const handleOutputLanguageClick = (language) => {
    setLanguageTwoDropdownVisible(false);
    if (language != outputLanguage.current) {
      if (messagesRef.current.length) setIsLoadingOutputTranslation(true);

      var textsToTranslate = [];
      var messagesAlreadyTranslated = [];
      for (var i = 0; i < messagesRef.current.length; i++) {
        var message = messagesRef.current[i];
        console.log('message: ' + message);
        if (message['corrected_transcripts'][language] != undefined) {
          messagesAlreadyTranslated.push(i);
          continue;
        }
        textsToTranslate.push(message.transcript);
      }
      console.log('texts to translate: ' + textsToTranslate);
      translateTexts(textsToTranslate, language).then((translations) => {
        console.log('translations: ' + translations);
        var updatedMessages = [];
        for (var i = 0; i < messages.length; i++) {
          var message = messages[i];
          if (messagesAlreadyTranslated.includes(i)) {
            updatedMessages.push(message);
            continue;
          } else {
            if (message['corrected_transcripts'][language] == undefined) {
              message['corrected_transcripts'][language] =
                translations[i] + ' ';
            }

            updatedMessages.push(message);
          }
        }
        setMessages(updatedMessages);
        updateMessagesMerged(updatedMessages);
        setIsLoadingOutputTranslation(false);
        // localStorage.setItem('messages', JSON.stringify(messagesMerged));
      });
    }
    outputLanguage.current = language;
    setOutputLanguageDisplay(language);
  };

  const handleTranscribeButton = async () => {
    if (userInfoRef.current?.credits <= 10) {
      setIsOutOfCreditsOpen(true);
      return;
    }
    console.log('isTranscribing', isTranscribingRef.current);
    if (isTranscribingRef.current) {
      stoppedTranscribing.current = true;
      isTranscribingRef.current = false;
      pauseTimer();
      await stopRecording();
      socket.emit('stop_transcribing', { jwt_token: jwtTokenRef.current });
    } else {
      stoppedTranscribing.current = false;
      isTranscribingRef.current = true;
      await startRecording();
      startTimer();
    }
    setTranscribing(isTranscribingRef.current);
  };

  const playAudio = async (text) => {
    try {
      if (playingAudioRef.current) {
        audioRef.current.pause();
        playingAudioRef.current = false;
      }
      const response = await axios.post(
        `${serverURL}/text_to_speech?jwt_token=${jwtTokenRef.current}`,
        { text: text }, // Correctly sending the body as the second argument
        {
          responseType: 'blob', // Ensure the response is a blob
        },
      );

      if (response.status === 200) {
        playingAudioRef.current = true;
        const audioBlob = response.data;

        // Create a URL for the audio blob and handle it as needed
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.play();
        audioRef.current = audio;
        audio.onended = () => {
          playingAudioRef.current = false;
        };
      } else {
        throw new Error(`Received status code ${response.status}`);
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const addBotToMeeting = async (meetingUrl) => {
    if (jwtTokenRef.current == undefined) {
      return;
    }

    try {
      const response = await axios.post(
        `${serverURL}/add_bot_to_zoom`,
        { meeting_url: meetingUrl },
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );

      if (response.status === 200) {
        console.log('Bot added to meeting');
        const { id, password } = response.data;
        navigate(`/broadcast/${id}?password=${password}`);
      } else {
        console.error('Failed to add bot to meeting');
      }
    } catch (error) {
      console.error('Error adding bot to meeting:', error);
    }
  };

  return (
    <div className='max-w-[1512px] mx-auto w-full relative text-left text-base text-updated-color-new-black font-montserrat flex flex-col 2xl:px-24'>
      {isOutOfCreditsOpen && (
        <OutOfCreditsWindow
          onClose={() => setIsOutOfCreditsOpen(false)}
          onBuyCredits={() => {
            setIsOutOfCreditsOpen(false);
            navigate('/buy_plan');
          }}
        />
      )}
      {showStartJoinARoomOverlay && (
        <StartJoinRoom
          onOutsideClickFunc={() => setShowStartJoinARoomOverlay(false)}
        />
      )}
      <div className='flex relative justify-center py-14 px-20 pb-28 h-screen'>
        <div className='flex flex-col gap-8 w-full relative'>
          <div className='flex items-center justify-center gap-4'>
            {isLoadingTranslation && (
              <div className='w-8 h-8 flex items-center justify-center'>
                <svg
                  className='animate-spin h-7 w-7 text-updated-color-blue'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-75'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className=''
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              </div>
            )}
            <div
              ref={inputLanguageRef}
              className='w-fit rounded-34xl flex py-1.5 px-5 border-[0.5px] border-solid border-updated-color-blue cursor-pointer'
              onClick={() => {
                setLanguageDropdownVisible(!languageDropdownVisible);
                usingInputLanguagePopupRef.current =
                  !usingInputLanguagePopupRef.current;
              }}
            >
              <div className='flex flex-col items-start justify-start py-1.5 px-2.5'>
                <div className='flex flex-row items-start justify-start gap-[10px] cursor-pointer'>
                  <b className='relative'>{inputLanguageDisplay}</b>
                  <img
                    className='w-5 relative h-5'
                    alt=''
                    src='/select-more-language.svg'
                  />
                </div>
              </div>
            </div>
          </div>

          {languageDropdownVisible && (
            <div
              ref={inputLanguageDropdownRef}
              className='grid grid-cols-4 gap-16 gap-y-10 pb-2 px-10 pr-20 bg-white border absolute top-12 border-solid border-gray-300 rounded-md shadow-lg z-10 max-h-full overflow-y-auto w-full'
            >
              <div className='col-span-4 h-fit flex items-center p-2 border-solid border-x-0 border-t-0 border-b border-gray-300'>
                <input
                  type='text'
                  placeholder='Search...'
                  className='w-full p-1 border-none outline-none text-lg font-montserrat'
                  value={searchBarInput}
                  onChange={(e) => setSearchBarInput(e.target.value)}
                />
                <img
                  className='w-5 h-5 ml-2'
                  alt=''
                  src='/general--search.svg'
                />
              </div>
              {top100WorldLanguages
                .filter((language) =>
                  language
                    .toLowerCase()
                    .includes(searchBarInput?.toLowerCase() || ''),
                )
                .map((language) => (
                  <div
                    key={language}
                    className='hover:bg-gray-200 cursor-pointer w-full h-fit'
                    onClick={() => handleInputLanguageClick(language)}
                  >
                    {language}
                  </div>
                ))}
            </div>
          )}
          <div className='relative shadow-[1px_1px_2.8px_rgba(140,_140,_140,_0.16)_inset] rounded-xl bg-gray box-border w-full h-[448px] border-[0.5px] border-solid border-updated-color-grey1 pb-10'>
            <div
              className='flex flex-col gap-4 overflow-y-auto max-h-[95%]'
              ref={messagesContainerRef}
            >
              {messagesMerged.map((message, index) => {
                const localTime = format(
                  new Date(message.timestamp * 1000),
                  'hh:mm:ss a',
                );
                return (
                  <div key={index} className='flex items-start m-4'>
                    <span className='mr-4 text-gray-600 w-24'>{localTime}</span>
                    <p className='inline'>
                      {inputLanguage.current === 'Original' ||
                      inputLanguage.current === 'Detect Language' ||
                      !message.corrected_transcripts[inputLanguage.current]
                        ? message.transcript
                        : message.corrected_transcripts[inputLanguage.current]}
                      <span className='inline text-gray-500'>
                        {inputLanguage.current === 'Original' ||
                        inputLanguage.current === 'Detect Language' ||
                        !message.unconfirmed_corrected_transcripts ||
                        !message.unconfirmed_corrected_transcripts[
                          inputLanguage.current
                        ]
                          ? message.unconfirmed_transcript
                          : message.unconfirmed_corrected_transcripts[
                              inputLanguage.current
                            ]}
                      </span>
                    </p>
                  </div>
                );
              })}
            </div>
            {!isTranscribing ? (
              <div className='absolute bottom-2 left-0 right-0 mx-auto w-fit flex gap-[20px]'>
                <img
                  className='w-[34px] relative h-[34px] cursor-pointer'
                  alt=''
                  src='/group-1707478176.svg'
                  onClick={() => {
                    handleTranscribeButton();
                  }}
                />
                <img
                  className='w-[34px] relative h-[34px] cursor-pointer'
                  alt=''
                  src='/group-1707478175.svg'
                  onClick={() => {
                    let copiedTranscript = '';
                    for (let i = 0; i < messagesMerged.length; i++) {
                      const message = messagesMerged[i];
                      let localTime = format(
                        new Date(message.timestamp * 1000),
                        'hh:mm:ss a',
                      );
                      copiedTranscript +=
                        localTime + ': ' + message['transcript'] + '\n\n';
                    }
                    navigator.clipboard.writeText(copiedTranscript);
                  }}
                />
              </div>
            ) : (
              <div className='absolute bottom-2 left-0 right-0 mx-auto flex flex-col gap-4 items-center justify-center'>
                {shouldShowScrollButton && (
                  <div
                    className='w-fit cursor-pointer'
                    onClick={scrollToBottom}
                  >
                    <Icon path={mdiArrowDown} size={1} />
                  </div>
                )}
                <div className='flex flex-row items-center justify-center text-center text-xs'>
                  <div className='flex flex-col items-start justify-start py-2.5 px-0'>
                    <img
                      className='w-full relative max-h-full'
                      alt=''
                      src='/vector-367.svg'
                    />
                  </div>
                  <div className='w-12 relative flex items-center justify-center shrink-0'>
                    {formatTime(elapsedTime)}
                  </div>
                  <div className='flex flex-col items-start justify-start py-2.5 px-0'>
                    <img
                      className='w-full relative max-h-full'
                      alt=''
                      src='/vector-367.svg'
                    />
                  </div>
                </div>
                <div className=' w-fit flex gap-[20px]'>
                  <img
                    className='w-[34px] relative h-[34px] cursor-pointer'
                    alt=''
                    src='/group-1707478175.svg'
                    onClick={() => {
                      var copiedTranscript = '';
                      for (var i = 0; i < messagesMerged.length; i++) {
                        const message = messagesMerged[i];
                        var localTime = format(
                          new Date(message.timestamp * 1000),
                          'hh:mm:ss a',
                        );
                        copiedTranscript +=
                          localTime + ': ' + message['transcript'] + '\n\n';
                      }
                      navigator.clipboard.writeText(copiedTranscript);
                    }}
                  />
                  <img
                    className='w-[34px] relative h-[34px] cursor-pointer'
                    alt=''
                    src='/group-1707478181.svg'
                    onClick={() => {
                      handleTranscribeButton();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            className='fixed bottom-20 left-0 rounded-tl-none rounded-tr-11xl rounded-br-11xl rounded-bl-none [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] h-11 flex flex-row items-center justify-center py-2.5 pr-5 pl-[49px] box-border gap-[5px] cursor-pointer w-fit'
            onClick={() => {
              setShowStartJoinARoomOverlay(true);
            }}
            onMouseOver={() => {
              setHoveringEnterBroadcast(true);
            }}
            onMouseOut={() => {
              setHoveringEnterBroadcast(false);
            }}
          >
            <div
              className={`font-semibold transition duration-500 ${hoveringEnterBroadcast && 'scale-125 -translate-x-5'}`}
            >
              Enter Broadcast
            </div>
            <img
              className={`w-6 h-6 transition duration-500 ${hoveringEnterBroadcast && '-rotate-45'}`}
              src='/arrow--arrow-right-1.svg'
            />
          </div>
        </div>
        <div className='w-fit'>
          <img
            className='w-9 h-9 cursor-pointer'
            alt=''
            src='/switch.svg'
            onClick={() => {
              const temp = inputLanguage.current;
              inputLanguage.current = outputLanguage.current;
              outputLanguage.current = temp;
              setInputLanguageDisplay(inputLanguage.current);
              setOutputLanguageDisplay(outputLanguage.current);
            }}
          />
        </div>
        <div className='flex flex-col gap-8 w-full relative'>
          <div className='flex items-center justify-center gap-4'>
            {isLoadingOutputTranslation && (
              <div className='w-8 h-8 flex items-center justify-center'>
                <svg
                  className='animate-spin h-7 w-7 text-updated-color-blue'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-75'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className=''
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              </div>
            )}
            <div
              ref={outputLanguageRef}
              className='w-fit rounded-34xl flex flex-row items-start justify-start py-1.5 px-5 border-[0.5px] border-solid border-updated-color-blue cursor-pointer'
              onClick={() => {
                usingOutputLanguagePopupRef.current =
                  !usingOutputLanguagePopupRef.current;
                setLanguageTwoDropdownVisible(!languageTwoDropdownVisible);
              }}
            >
              <div className='flex flex-col items-start justify-start py-1.5 px-2.5'>
                <div className='flex flex-row items-start justify-start gap-[10px]'>
                  <b className='relative'>{outputLanguageDisplay}</b>
                  <img
                    className='w-5 relative h-5'
                    alt=''
                    src='/select-more-language.svg'
                  />
                </div>
              </div>
            </div>
          </div>

          {languageTwoDropdownVisible && (
            <div
              ref={outputLanguageDropdownRef}
              className='absolute right-0 top-12 gap-16 gap-y-10 px-10 pr-20 bg-white border-solid grid grid-cols-4 border-gray-300 rounded-md shadow-lg z-10 w-full max-h-full overflow-y-auto'
            >
              <div className='flex h-fit items-center col-span-4 p-2 border-solid border-x-0 border-t-0 border-b border-gray-300'>
                <input
                  type='text'
                  placeholder='Search...'
                  className='w-full p-1 border-none outline-none text-lg font-montserrat'
                  value={searchBarTwoInput}
                  onChange={(e) => setSearchBarTwoInput(e.target.value)}
                />
                <img
                  className='w-5 h-5 ml-2'
                  alt=''
                  src='/general--search.svg'
                />
              </div>
              {top100WorldLanguages
                .filter((language) =>
                  language
                    .toLowerCase()
                    .includes(searchBarTwoInput?.toLowerCase() || ''),
                )
                .map((language) => (
                  <div
                    key={language}
                    className='p-2 hover:bg-gray-200 cursor-pointer w-fit h-fit'
                    onClick={() => handleOutputLanguageClick(language)}
                  >
                    {language}
                  </div>
                ))}
            </div>
          )}
          <div className='relative shadow-[1px_1px_2.8px_rgba(140,_140,_140,_0.16)_inset] rounded-xl bg-updated-color-translate-box-bkg box-border w-full h-[448px] border-[0.5px] border-solid border-updated-color-grey1 pb-10'>
            <div
              className='flex flex-col gap-4 overflow-y-auto max-h-[95%]'
              ref={correctedMessagesContainerRef}
            >
              {messagesMerged.map((message, index) => {
                const localTime = format(
                  new Date(message.timestamp * 1000),
                  'hh:mm:ss a',
                );
                return (
                  <div key={index} className='flex items-start m-4'>
                    <span className='mr-4 text-gray-600 w-24'>{localTime}</span>
                    <p className='inline'>
                      {outputLanguage.current === 'Original' ||
                      outputLanguage.current === 'Detect Language' ||
                      !message.corrected_transcripts[outputLanguage.current]
                        ? message.transcript
                        : message.corrected_transcripts[outputLanguage.current]}
                      <span className='inline text-gray-500'>
                        {outputLanguage.current === 'Original' ||
                        outputLanguage.current === 'Detect Language' ||
                        !message.unconfirmed_corrected_transcripts?.hasOwnProperty(
                          outputLanguage.current,
                        )
                          ? message.unconfirmed_transcript
                          : message.unconfirmed_corrected_transcripts[
                              outputLanguage.current
                            ]}
                      </span>
                    </p>
                  </div>
                );
              })}
            </div>

            <div className='absolute bottom-2 left-0 right-0 mx-auto w-fit flex flex-col items-center gap-[20px]'>
              {correctedMessagesShouldShowScrollButton && (
                <div
                  className=' cursor-pointer'
                  onClick={correctedScrollToBottom}
                >
                  <Icon path={mdiArrowDown} size={1} />
                </div>
              )}
              <div className='flex items-center gap-4'>
                <img
                  className='w-[34px] relative h-[34px] cursor-pointer'
                  alt=''
                  src='/group-1707478179.svg'
                  onClick={() => {
                    var copiedTranscript = '';
                    for (var i = 0; i < messagesMerged.length; i++) {
                      const message = messagesMerged[i];
                      copiedTranscript +=
                        message.corrected_transcripts[
                          outputLanguage.current
                        ] !== undefined
                          ? message.corrected_transcripts[
                              outputLanguage.current
                            ]
                          : message.transcript;
                      copiedTranscript += '\n\n';
                    }
                    playAudio(copiedTranscript);
                  }}
                />
                <img
                  className='w-[34px] relative h-[34px] cursor-pointer'
                  alt=''
                  src='/group-1707478175.svg'
                  onClick={() => {
                    var copiedTranscript = '';
                    for (var i = 0; i < messagesMerged.length; i++) {
                      const message = messagesMerged[i];
                      var localTime = format(
                        new Date(message.timestamp * 1000),
                        'hh:mm:ss a',
                      );
                      copiedTranscript +=
                        localTime +
                        ': ' +
                        message.corrected_transcripts[outputLanguage.current] +
                        '\n\n';
                    }
                    navigator.clipboard.writeText(copiedTranscript);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-4 ml-14 min-w-80 max-w-80'>
          <div className='flex flex-col justify-center relative w-full gap-1'>
            <img
              src='/cam.svg'
              alt=''
              className='w-6 h-6 absolute top-2 left-2'
            />
            <input
              placeholder='Paste Meeting URL or Video Link'
              className='text-base pl-8 py-2.5 border-solid rounded-full border border-updated-color-grey1 focus:outline-none bg-transparent shadow'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addBotToMeeting(e.target.value);
                }
              }}
            />
            <div className='flex items-center text-[#c5c5c5] font-bold self-end gap-1'>
              Supports
              <img
                src='/platforms.svg'
                alt='Meeting platform logos'
                className='w-20'
              />
            </div>
          </div>
          <RecentsSidebar />
        </div>
      </div>
      <div style={{ display: isChatbotOpen ? 'block' : 'none' }}>
        <ChatbotWidget
          isChatbotOpenRef={isChatbotOpenRef}
          onClose={() => setIsChatbotOpen(false)}
          contextRef={chatbotContextRef}
        />
      </div>
      {!isChatbotOpen && (
        <img
          className='cursor-pointer fixed bottom-5 right-5'
          src='chatbot-button.svg'
          alt='Chatbot'
          onClick={() => setIsChatbotOpen(true)}
        />
      )}
    </div>
  );
};

export default Transcribe;
