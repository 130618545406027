import { Link } from 'react-router-dom';
import SEOScript from './SEOScript';

const HomepageMobile = () => {

    // check the url query parameter utm_source
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get('utm_source');
    if (utmSource) {
      localStorage.setItem('signup_source', utmSource);
    }
    
  return (
    <div className='bg-white min-h-screen text-left text-5xl text-black font-montserrat flex flex-col justify-center items-center p-20 px-1 gap-20 text-not-black'>
      <SEOScript></SEOScript>
      <div
        className='text-center flex flex-col gap-2 items-center rounded-lg bg-cover justify-center relative shadow-md pt-10 pb-20'
        style={{ backgroundImage: 'url(./dots.png)' }}
      >
        <img
          src='/iPhone15.png'
          alt='iPhone'
          className='w-56 absolute top-0 bottom-0 my-auto left-0'
        />
        <img
          src='/chatbot.png'
          alt='Chatbot'
          className='w-24 absolute right-0 top-0'
        />
        <img
          src='/folder.png'
          alt='Folder'
          className='w-2/6 absolute bottom-0 right-3'
        />
        <img src='/logo.svg' alt='Logo' className='w-6 z-10' />
        <div className='text-[1.75rem] font-bold z-10'>Every conversation,</div>
        <div className='text-[1.75rem] z-10 text-gray-500 font-bold -mt-2'>
          Perfectly captured
        </div>
        <div className='text-base text-center z-10 font-medium px-4'>
          Elevate your communication with real-time transcription, enhance your
          conversations with precision and clarity
        </div>
        <div className='flex justify-center w-fit mx-auto items-center gap-2'>
          <Link
            to='/welcome'
            className='z-10 bg-blue-500 text-sm text-white rounded-full p-1 px-2 no-underline hover:bg-blue-600 transition-colors duration-200'
          >
            Sign up for Free
          </Link>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center gap-6'>
        <div className='text-base'>Integrated with: </div>
        <div className='grid grid-cols-2 gap-x-0 gap-4 place-items-center pr-4 mx-auto'>
          <img src='/zoom.png' alt='Zoom' className='w-1/2' />
          <img src='/teams.png' alt='Teams' className='w-3/4' />
          <img src='/slack.png' alt='Slack' className='w-1/2' />
          <img src='/gmeet.png' alt='Google Meet' className='w-11/12' />
        </div>
      </div>
      <div className='text-left flex flex-col justify-center gap-6 pl-4'>
        <div className='text-base'>OUR MISSION</div>
        <div className='text-2xl font-bold'>
          Interpret AI helps break down communication barriers.
          <br /> <br /> Our platform empowers teams to capture and translate
          conversations in real-time.
        </div>
      </div>
      <div
        style={{ backgroundImage: 'url(./stripes.png)' }}
        className='rounded-lg shadow-md w-12/12 bg-white text-base text-left flex flex-col justify-center items-center gap-6 px-2 py-20'
      >
        <div className='text-2xl font-bold w-fit flex items-center'>
          Introducing,&nbsp;
          <div className='font-bold text-dodgerblue-200 flex gap-2 items-center w-full'>
            interpret
            <img src='/logo.svg' alt='Logo' className='w-8' />
          </div>
        </div>
        <div className='flex flex-col items-center text-center gap-6 mb-6'>
          <div className='text-xl font-semibold'>created by BlueJay AI</div>
          <div className='font-thin text-sm px-8 text-updated-color-grey2'>
            Enabling individuals facing language barriers to enjoy seamless
            conversations with an AI-powered transcription tool.
          </div>
        </div>
        <div className='flex flex-col justify-between w-fit gap-14'>
          <div
            className='flex flex-col shadow-md gap-2 p-2 bg-[#DBDAF8] rounded-lg p-4 px-6 pb-4 bg-no-repeat bg-cover'
            style={{ backgroundImage: 'url(./screens.png)' }}
          >
            <div className='text-base text-sm'>
              REAL-TIME SPEECH TRANSCRIBING
            </div>
            <div className='font-bold rounded-lg'>
              Capture and understand every conversation with precise, real-time
              transcription
            </div>
            <img src='/example.svg' alt='Example' className='w-44 self-end' />
          </div>
          <div className='flex flex-col border border-solid border-[#dadada] shadow-md p-4 gap-4 pb-10 w-fit bg-blue-200 rounded-lg px-6 -mt-6'>
            <div className='text-sm'>MEETING ROOM ADD-ON</div>
            <div className='font-bold'>
              Receive live subtitles for all your meetings, making it easier to
              follow along and stay engaged
            </div>
            <div className='flex gap-4 items-center justify-center'>
              <img src='/integrations.svg' className='w-1/2' />
              <img src='/popups.svg' className='w-1/2' />
            </div>
          </div>
        </div>
        <div className='w-fit rounded-lg shadow-md p-4 flex flex-col bg-white items-center'>
          <div className='flex flex-col gap-4'>
            <div className='text-sm'>LIVE BROADCAST ROOM SHARING</div>
            <div className='font-bold'>
              Join live events remotely and read the speech in real-time,
              translated into your preferred language
            </div>
            <img
              src='/webglobe.png'
              alt='globe'
              className='w-full mx-auto rounded-md'
            />
          </div>
          <img
            src='/mobilepins.svg'
            alt='Pins'
            className='pt-4 w-72 self-center'
          />
        </div>
        <div className='flex flex-col items-center gap-10 '>
          <div className='flex flex-col w-full gap-6'>
            <div className='flex flex-col gap-4 bg-neutral-400 rounded-lg p-4 pb-6 shadow-md'>
              <div className=' text-sm'>ACCURATE AI ASSISTANCE</div>
              <div className='font-bold'>
                Benefit from advanced AI for highly accurate transcription and
                translation results
              </div>
            </div>
            <div className='flex flex-col gap-4 h-full bg-[#EFEFEF] rounded-lg p-6 shadow-md'>
              <div className='text-sm'>AUDIO TRANSCRIPTION & TRANSLATION</div>
              <div className='font-bold'>
                Capture speech instantly and translate it seamlessly into your
                language of choice — quick, precise, and effortless
                communication at your fingertips
              </div>
              <img src='/docs.svg' alt='Docs' className='max-w-full mt-4' />
            </div>
          </div>
          <div className='flex flex-col gap-4 bg-[#bad9b0] rounded-lg p-6 px-4 shadow-md -mt-3'>
            <div className=' text-sm'>EASY DOCUMENT MANAGEMENT</div>
            <div className='font-bold'>
              Store, organize, and access all your transcribed and translated
              documents in one convenient location
            </div>
            <img
              src='/mobilepics.svg'
              alt='mobile pics'
              className='max-w-full mt-4'
            />
          </div>
        </div>
      </div>
      <a href="https://theresanaiforthat.com/ai/interpret-ai/?ref=featured&v=3528940" target="_blank" rel="nofollow"><img width="300" src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600"/></a>
    </div>
  );
};

export default HomepageMobile;
