import { Link } from 'react-router-dom';

export const TOS = () => {
  return (
    <div className='min-h-screen bg-white font-montserrat flex flex-col gap-14 px-10 pb-20 sm:px-48 pt-28'>
      <div className='text-[40px] font-semibold text-not-black'>
        Terms of Service
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Acceptance of Terms
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          By accessing or using Interpret AI, a service provided by BlueJay AI
          Technologies Inc., you agree to comply with and be bound by these
          Terms of Service. If you do not agree to these terms, please do not
          use our services.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Description of Services
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          Interpret AI provides transcription and translation services through
          our website (www.interpretapp.ai) and mobile applications available on
          the Apple App Store and Google Play Store. Our services also include
          features for saving, editing, summarizing, and managing transcribed or
          translated files. The use of any of Interpret AI's services is subject
          to the conditions outlined in these terms.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          User Registration
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          To use certain features of the service, you are required to register
          for an account. You agree to provide accurate and complete information
          during the registration process.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>Payment</div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          Subscriptions can be purchased to access our transcription and
          translation services. The cost of subscriptions is listed on our
          purchase page (www.interpretapp.ai/purchase) and within the Interpret
          App on both the Apple App Store and Google Play Store.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Refund Policy
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          We offer refunds on any subscription purchased and not used within a
          24-hour window from the time of purchase. If a refund is requested
          after the app has been used within the 24-hour window, our customer
          support team may approve or deny the request at their discretion.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          User Conduct
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6 mb-4'>
          You agree not to:
        </div>
        <ul className='text-updated-color-grey2 font-medium text-sm leading-6'>
          <li>Violate any laws or regulations.</li>
          <li>Infringe on the rights of others.</li>
          <li>Use the service for any illegal or unauthorized purpose.</li>
          <li>Attempt to access data not intended for you.</li>
        </ul>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>Privacy</div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          Your use of Interpret AI is also governed by our{' '}
          <Link to='/privacy'>Privacy Policy.</Link> Please review it to
          understand our practices.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Termination
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          We reserve the right to terminate or suspend your account at our
          discretion without notice for any violation of these terms.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Intellectual Property
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          The content, features, and functionality of Interpret AI are owned by
          BlueJay AI Technologies Inc. and are protected by trademarks and other
          intellectual property laws.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Disclaimer of Warranties
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          Interpret AI is provided "as is" without warranties of any kind,
          either expressed or implied.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Limitation of Liability
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          We shall not be liable for any indirect, incidental, special,
          consequential, or punitive damages while users are utilizing our
          service.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Changes to Terms of Service
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          We reserve the right to update or modify these terms at any time. Any
          changes will be effective immediately upon posting and notified to all
          registered users via their registered email.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Governing Law
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          These terms are governed by and construed in accordance with the laws
          of the State of Delaware.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Contact Us
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          If you have any questions or concerns about these Terms of Service,
          please contact us at support@interpretapp.ai.
        </div>
      </div>
    </div>
  );
};
