// reads the languages.json list and parses it.
import languages from './languages.json';
console.log(languages);
const top100WorldLanguages = [];
for (let i = 0; i < languages.length; i++) {
  top100WorldLanguages.push(languages[i]['English']);
}

let languageToCode = {};
for (let i = 0; i < languages.length; i++) {
  languageToCode[languages[i]['English']] = languages[i]['alpha2'];
}

const fileTranslateLanguages = [
  'Amharic',
  'Arabic',
  'Armenian',
  'Azerbaijani',
  'Bengali',
  'Bulgarian',
  'Burmese',
  'Cantonese',
  'Chavacano',
  'Chinese (Simplified)',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'English',
  'Estonian',
  'Filipino (Tagalog)',
  'Finnish',
  'French',
  'Georgian',
  'German',
  'Greek',
  'Haitian Creole',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Icelandic',
  'Irish',
  'Italian',
  'Japanese',
  'Kazakh',
  'Khmer',
  'Korean',
  'Lao',
  'Latin',
  'Latvian',
  'Lithuanian',
  'Macedonian',
  'Malay',
  'Malayalam',
  'Maltese',
  'Marathi',
  'Mongolian (Cyrillic)',
  'Norwegian',
  'Persian',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Romanian',
  'Russian',
  'Serbian (Cyrillic letters)',
  'Serbian (Latin letters)',
  'Sinhala',
  'Slovak',
  'Slovenian',
  'Spanish',
  'Swahili',
  'Swedish',
  'Tagalog',
  'Tamil',
  'Telugu',
  'Thai',
  'Tigrinya',
  'Turkish',
  'Uighur',
  'Ukrainian',
  'Uzbek',
  'Vietnamese',
  'Yiddish',
];

console.log(top100WorldLanguages);

export { top100WorldLanguages, languageToCode, fileTranslateLanguages };
