import { useEditor, EditorContent } from '@tiptap/react';
import { forwardRef, useImperativeHandle, useEffect } from 'react';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';

// Define your extension array
const extensions = [StarterKit, Highlight];

export const Editor = forwardRef(
  (
    {
      content,
      handleChange,
      isRemovingTimestamps,
      setCanRedo,
      setCanUndo,
      includeTimestamps,
    },
    ref,
  ) => {
    // Initialize the editor with the provided content
    const editor = useEditor({
      extensions,
      content: content ? convertNewLinesToHTML(content) : '', // Convert plain text to HTML if necessary
      editorProps: {
        attributes: {
          class: 'focus:outline-none mx-auto w-11/12 font-medium',
        },
      },
      onUpdate: ({ editor }) => {
        const html = editor.getHTML();
        handleChange(html); // Pass the updated content back to the parent
        setCanUndo(editor.can().undo());
        setCanRedo(editor.can().redo());
      },
    });

    // Expose imperative methods to the parent component
    useImperativeHandle(ref, () => ({
      undo: () => editor.chain().focus().undo().run(),
      redo: () => editor.chain().focus().redo().run(),
      highlight: () => editor.chain().focus().toggleHighlight().run(),
    }));

    // Update the editor's content when the `content` prop changes
    useEffect(() => {
      if (editor?.isEmpty) {
        editor.commands.setContent(convertNewLinesToHTML(content));
      }
      if (!isRemovingTimestamps && !includeTimestamps) {
        editor.commands.setContent(convertNewLinesToHTML(content));
      } else if (includeTimestamps) {
        editor.commands.setContent(convertNewLinesToHTML(content));
      }
    }, [content, editor, isRemovingTimestamps, includeTimestamps]);

    if (!editor) {
      return null; // Render nothing if the editor isn't initialized
    }

    return <EditorContent className='w-full' editor={editor} />;
  },
);

// Utility function to convert plain text with newlines to HTML
const convertNewLinesToHTML = (text) => {
  if (!text) return '';

  // Replace double newlines with paragraph tags and single newlines with line breaks
  const paragraphs = text
    .split('\n\n')
    .map((paragraph) => `<div>${paragraph.replace(/\n/g, '<br>')}</div>`);
  return paragraphs.join('').trim();
};
