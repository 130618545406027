import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { jwtTokenRef, serverURL, userInfoRef } from '../../httpContext';

const ProfileBuyPlan = () => {
  const navigate = useNavigate();
  const [currentPlan, setCurrentPlan] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const selectedPlanRef = useRef(selectedPlan);

  useEffect(() => {
    const loadUserPlan = async () => {
      // Wait until userInfoRef is populated
      while (!userInfoRef.current || !userInfoRef.current.email) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      setCurrentPlan(userInfoRef.current?.plan);
    };
    loadUserPlan();
  }, []);

  useEffect(() => {
    selectedPlanRef.current = selectedPlan;
  }, [selectedPlan]);

  const handleSubscribe = async (plan) => {
    const getPromoCode = async () => {
      const response = await fetch(serverURL + '/generate_discount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtTokenRef.current,
        },
        body: JSON.stringify({
          // Add any required data for the verification here
        }),
      });

      if (response.ok) {
        const data = await response.json();
        return data.promo_code;
      }
      return '';
    };

    const openOrRedirect = (url) => {
      const newWindow = window.open(url, '_blank');
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === 'undefined'
      ) {
        window.location.href = url;
      }
    };

    const baseUrl =
      plan === 'lite'
        ? 'https://buy.stripe.com/3cs6pw55jcuZ5Og4go'
        : 'https://buy.stripe.com/28og06btH66BdgIdQW';

    let promoCode = '';
    if (
      (plan === 'lite' && userInfoRef.current?.plan === 'pro') ||
      (plan === 'pro' && userInfoRef.current?.plan === 'lite')
    ) {
      promoCode = await getPromoCode();
    }

    const fullUrl = `${baseUrl}?prefilled_email=${userInfoRef.current.email}${promoCode ? '&prefilled_promo_code=' + promoCode : ''}`;
    openOrRedirect(fullUrl);
  };

  return (
    <div className='w-full bg-white h-screen p-4 font-montserrat'>
      {/* Header Section */}
      <div className='flex flex-col items-center justify-center text-center py-10 px-4'>
        <h1 className='text-4xl md:text-[40px] font-semibold text-not-black-black mb-5 leading-tight'>
          Plans & Pricing
        </h1>
        <p className='max-w-2xl text-sm font-medium text-not-black-black'>
          Choose the plan that fits your needs and enjoy full access to all
          features. Get the best value based on how much you use our services.
        </p>
      </div>

      {/* Plans Grid */}
      <div className='flex flex-col md:flex-row items-center md:items-stretch justify-center gap-8 md:gap-[70px] px-4 max-w-7xl mx-auto'>
        {/* Lite Plan */}
        <div className='w-full max-w-sm rounded-xl bg-gradient-to-br from-[rgba(0,126,243,0.1)] to-[rgba(121,119,209,0.1)] p-10 flex flex-col justify-between'>
          <div className='space-y-5'>
            <div className='rounded-mini bg-updated-color-blue py-[5px] px-[30px] inline-block'>
              <span className='text-xl font-medium text-white'>Lite</span>
            </div>

            <div className='space-y-10'>
              <span className='text-[32px] font-bold text-not-black-black block'>
                $8.99/mo
              </span>

              <div className='space-y-3'>
                {[
                  '1,000 credits monthly (0.9 cents per credit)',
                  'Up to 20,000 words of document translation',
                  'Up to 16.6 hours of real-time translation',
                  'Up to 16.6 hours of audio/video translation',
                ].map((feature, index) => (
                  <div key={index} className='flex items-start gap-3'>
                    <div className='w-2 h-2 rounded-full bg-updated-color-blue mt-1.5' />
                    <span className='text-xs text-not-black-black'>
                      {feature}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <button
            disabled={currentPlan === 'Lite Plan'}
            onClick={() => {
              if (currentPlan !== 'lite') {
                setSelectedPlan('lite');
                handleSubscribe('lite');
              }
            }}
            className={`mt-16 w-full rounded-[32px] bg-white bg-opacity-50 py-3 px-4 text-[15px] font-medium text-not-black-black ${currentPlan === 'lite' ? 'cursor-not-allowed' : 'hover:bg-opacity-70 transition-colors'}`}
          >
            {currentPlan === 'pro'
              ? 'Change Plan'
              : currentPlan === 'lite'
                ? 'Current Plan'
                : 'Start now'}
          </button>
        </div>

        {/* Pro Plan */}
        <div className='w-full max-w-sm rounded-xl bg-gradient-to-br from-[rgba(0,126,243,0.1)] to-[rgba(121,119,209,0.1)] p-10 flex flex-col justify-between'>
          <div className='space-y-5'>
            <div className='rounded-mini bg-updated-color-blue py-[5px] px-[30px] inline-block'>
              <span className='text-xl font-medium text-white'>Pro</span>
            </div>

            <div className='space-y-10'>
              <span className='text-[32px] font-bold text-not-black-black block'>
                $24.99/mo
              </span>

              <div className='space-y-3'>
                {[
                  '3,000 credits monthly (0.8 cents per credit)',
                  'Up to 60,000 words of document translation (60 pages)',
                  'Up to 50 hours of real-time translation',
                  'Up to 50 hours of audio/video translation',
                  'Up to 20 hours of Zoom translation',
                ].map((feature, index) => (
                  <div key={index} className='flex items-start gap-3'>
                    <div className='w-2 h-2 rounded-full bg-updated-color-blue mt-1.5' />
                    <span className='text-xs text-not-black-black'>
                      {feature}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <button
            disabled={currentPlan === 'Pro Plan'}
            onClick={() => {
              if (currentPlan !== 'pro') {
                setSelectedPlan('pro');
                handleSubscribe('pro');
              }
            }}
            className={`mt-16 w-full rounded-[32px] bg-white bg-opacity-50 py-3 px-4 text-[15px] font-medium text-not-black-black ${currentPlan === 'pro' ? 'cursor-not-allowed' : 'hover:bg-opacity-70 transition-colors'}`}
          >
            {currentPlan === 'lite'
              ? 'Upgrade now (Discount applied)'
              : currentPlan === 'pro'
                ? 'Current Plan'
                : 'Start now'}
          </button>
        </div>

        {/* Enterprise Plan */}
        <div className='w-full max-w-sm rounded-xl bg-gradient-to-br from-[rgba(0,126,243,0.1)] to-[rgba(121,119,209,0.1)] p-10 flex flex-col justify-between'>
          <div className='space-y-5'>
            <div className='rounded-mini bg-updated-color-blue py-[5px] px-[30px] inline-block'>
              <span className='text-xl font-medium text-white'>Enterprise</span>
            </div>

            <div className='space-y-10'>
              <span className='text-[32px] font-bold text-not-black-black block'>
                Let's Talk!
              </span>

              <div className='space-y-3'>
                <div className='flex items-start gap-3'>
                  <div className='w-2 h-2 rounded-full bg-updated-color-blue mt-1.5' />
                  <span className='text-xs text-not-black-black'>
                    Contact us to discuss custom solutions and pricing.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={() => navigate('/contact')}
            className='mt-16 w-full rounded-[32px] bg-white bg-opacity-50 py-3 px-4 text-[15px] font-medium text-not-black-black hover:bg-opacity-70 transition-colors'
          >
            Contact us
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileBuyPlan;
