const SEOScript = () => {
  return (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Real-Time AI Translator, Notetaker & Broadcast Tool - Multilingual & Accurate",
        "description":
          "Experience the most accurate AI transcription and translation tool for live meetings, broadcasts, and events. Seamlessly integrates with Zoom, Microsoft Teams, and Google Meet. Translate files, videos, and audio in real-time with support for more languages than Otter.ai.",
        "alternateName": [
          { "@language": "es", "value": "Traductor AI en tiempo real: Herramienta para reuniones y eventos" },
          { "@language": "fr", "value": "Traducteur IA en temps réel: Outil pour réunions et événements" },
          { "@language": "de", "value": "Echtzeit-AI-Übersetzer: Tool für Meetings und Events" },
          { "@language": "zh", "value": "实时AI翻译器：会议和活动工具" },
          { "@language": "ko", "value": "실시간 AI 번역기: 회의 및 이벤트 도구" },
          { "@language": "ja", "value": "リアルタイムAI翻訳機：会議およびイベント用ツール" }
        ],
        "url": "https://interpretapp.ai",
        "author": {
          "@type": "Organization",
          "name": "Interpret AI",
          "url": "https://interpretapp.ai"
        },
        "keywords": [
          "AI Translator",
          "Real-Time Transcription",
          "Live Event Translation",
          "Zoom AI Translator",
          "Microsoft Teams Translator",
          "Google Meet Translator",
          "AI Notetaker",
          "AI Broadcast Translator",
          "Live Captions",
          "File Translate",
          "Video Translate",
          "Audio Translate",
          "More Accurate than Otter.ai",
          "More Languages than Otter.ai",
          "Multilingual AI Translation",
        ],
        "publisher": {
          "@type": "Organization",
          "name": "Interpret AI",
          "logo": {
            "@type": "ImageObject",
            "url": "https://interpretapp.ai/1024px-x-1024px-round-w-text1.svg",
            "width": 41,
            "height": 41
          }
        },
        "datePublished": "2024-11-26",
        "dateModified": "2024-11-26",
        "inLanguage": "en",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.blog.interpretapp.ai/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        },
        "hasPart": [
          {
            "@type": "SoftwareApplication",
            "name": "Zoom Integration",
            "description": "Seamlessly translate and transcribe Zoom meetings in real-time with multilingual support.",
            "operatingSystem": "All"
          },
          {
            "@type": "SoftwareApplication",
            "name": "Microsoft Teams Integration",
            "description": "Real-time AI translation for Teams meetings with live captions and notes.",
            "operatingSystem": "All"
          },
          {
            "@type": "SoftwareApplication",
            "name": "Google Meet Integration",
            "description": "Translate and transcribe live meetings on Google Meet with accuracy and speed.",
            "operatingSystem": "All"
          }
        ],
        "mainEntity": [
          { "@type": "Service", "name": "AI Notetaker" },
          { "@type": "Service", "name": "Live Captions" },
          { "@type": "Service", "name": "File Translation" },
          { "@type": "Service", "name": "Video Translation" },
          { "@type": "Service", "name": "Audio Translation" },
          { "@type": "Service", "name": "AI Broadcast Translator" }
        ]
      })}
    </script>
  );
};

export default SEOScript;
