import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtTokenRef, serverURL, userInfoRef } from '../../httpContext';
import ProfileDropdown from '../../components/ProfileDropdown';
import RecentsSidebar from './RecentsSidebar';
import { fileTranslateLanguages } from '../../languages';
import { LoadingOutlined } from '@ant-design/icons';
import { extractMeetingId } from '../../utils';
import { OutOfCreditsWindow } from '../../components/OutOfCreditsWindow';

const LanguageSelectDropdown = ({
  isLanguageSelectOpen,
  setIsLanguageSelectOpen,
  fileTranslateLanguages,
  setOutputLanguage,
}) => {
  const [searchBarInput, setSearchBarInput] = useState('');
  const [newOutputLanguage, setNewOutputLanguage] = useState('');

  return (
    isLanguageSelectOpen && (
      <div className='fixed inset-0 bg-black/20 flex items-center justify-center z-20 font-montserrat'>
        <div className='flex flex-col items-center gap-10 bg-white rounded-xl p-10 w-1/2'>
          <div className='self-start text-lg'>Select Language</div>
          <div className='grid grid-cols-4 bg-white border-solid border-gray-300 rounded-md shadow-lg max-h-64 overflow-y-auto min-w-full p-4'>
            <div className='col-span-4 flex items-center p-2 border-b border-x-0 border-t-0 border-solid border-gray-300'>
              <input
                type='text'
                placeholder='Search...'
                className='w-full p-1 border-none outline-none text-lg'
                value={searchBarInput}
                onChange={(e) => setSearchBarInput(e.target.value)}
              />
              <img className='w-5 h-5 ml-2' alt='' src='/general--search.svg' />
            </div>
            {fileTranslateLanguages
              .filter((language) =>
                language.toLowerCase().includes(searchBarInput.toLowerCase()),
              )
              .map((language) => (
                <div
                  key={language}
                  className={`p-2 hover:bg-black/20 rounded-full cursor-pointer h-fit w-fit ${newOutputLanguage === language ? 'bg-dodgerblue-200 text-white' : ''}`}
                  onClick={() => {
                    console.log('setting new output language to', language);
                    setNewOutputLanguage(language);
                  }}
                >
                  {language}
                </div>
              ))}
          </div>
          <div className='flex self-end gap-5 text-base'>
            <div
              className='rounded-3xs flex items-center justify-center py-1 px-4 border-[.5px] border-solid border-gray-300 cursor-pointer'
              onClick={() => setIsLanguageSelectOpen(false)}
            >
              <div className='relative leading-5 font-medium'>Cancel</div>
            </div>
            <div
              className='rounded-3xs bg-blue-300 flex items-center justify-center py-1 px-4 cursor-pointer'
              onClick={() => {
                console.log('setting output language to', newOutputLanguage);
                setOutputLanguage(newOutputLanguage);
                setIsLanguageSelectOpen(false);
              }}
            >
              <div className='relative leading-5 font-medium'>Done</div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const FileTranslate = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isLanguageSelectOpen, setIsLanguageSelectOpen] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [outputLanguage, setOutputLanguage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0); // New state for upload progress
  const [isOutOfCreditsOpen, setIsOutOfCreditsOpen] = useState(false);

  const fileInputRef = useRef(null);

  const uploadFile = async (file) => {
    let endpoint = `/translate_file_async?output_language=${outputLanguage}&regenerate_pdf=false`;
    const formData = new FormData();
    formData.append('file', file, file.name);
    try {
      setUploadedFileName('');
      setIsTranscribing(true);
      const response = await axios.post(serverURL + endpoint, formData, {
        headers: {
          Authorization: 'Bearer ' + jwtTokenRef.current,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (event) => {
          const { loaded, total } = event;
          const percentCompleted = Math.round((loaded / total) * 100);
          setUploadProgress(percentCompleted); // Update the progress
        },
      });

      if (response.status !== 200) {
        console.error('Error occurred during file upload.');
        setError('Error occurred during file upload.');
        setIsTranscribing(false);
        return;
      } else {
        const data = response.data;
        let transcriptId = data.transcript_id;
        setError('');
        navigate('/file_transcript?id=' + transcriptId);
      }
    } catch (error) {
      setIsTranscribing(false);
      setUploadedFileName('');

      if (error.response && error.response.status === 402) {
        // Handle specific case for insufficient credits
        setIsOutOfCreditsOpen(true);
      } else {
        console.error('Error occurred during file upload.', error);
        setError('Error occurred during file upload.');
      }
    }
  };

  const changeUploadedFileEventLister = () => {
    if (fileInputRef.current.files.length > 0) {
      const fileName = fileInputRef.current.files[0].name;
      const shortenedFileName =
        fileName.length > 20
          ? fileName.substring(0, 20) + '...' + fileName.split('.').pop()
          : fileName;
      setUploadedFileName(shortenedFileName);
    }
  };

  const addEventListenerToFile = () => {
    const fileInput = fileInputRef.current;
    if (fileInput) {
      fileInput.addEventListener('change', changeUploadedFileEventLister);
    }

    return () => {
      if (fileInput) {
        fileInput.removeEventListener('change', changeUploadedFileEventLister);
      }
    };
  };

  const addBotToMeeting = async (meetingUrl) => {
    if (jwtTokenRef.current == undefined) {
      return;
    }

    try {
      const response = await axios.post(
        `${serverURL}/add_bot_to_zoom`,
        { meeting_url: meetingUrl },
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );

      if (response.status === 200) {
        console.log('Bot added to meeting');
        const { id, password } = response.data;
        navigate(`/broadcast/${id}?password=${password}`);
      } else {
        console.error('Failed to add bot to meeting');
      }
    } catch (error) {
      console.error('Error adding bot to meeting:', error);
    }
  };

  useEffect(() => {
    addEventListenerToFile();
  }, []);

  return (
    <div className='relative max-w-[1512px] mx-auto'>
      {isOutOfCreditsOpen && (
        <OutOfCreditsWindow
          onClose={() => {
            setIsOutOfCreditsOpen(false);
          }}
          onBuyCredits={() => {
            setIsOutOfCreditsOpen(false);
            navigate('/buy_plan');
          }}
        ></OutOfCreditsWindow>
      )}
      <LanguageSelectDropdown
        isLanguageSelectOpen={isLanguageSelectOpen}
        setIsLanguageSelectOpen={setIsLanguageSelectOpen}
        fileTranslateLanguages={fileTranslateLanguages}
        setOutputLanguage={setOutputLanguage}
      />
      <div className='text-left text-xl text-updated-color-new-black font-montserrat flex flex-col'>
        {isTranscribing && (
          <div className='flex flex-col items-center justify-center gap-[20px] text-center text-[28px] py-36'>
            <LoadingOutlined
              className='text-[#007EF3] mb-6'
              style={{ fontSize: '60px' }}
            />
            <b className='relative leading-[22px]'>Translating...</b>
            <div className='relative text-lg leading-[22px] font-semibold'>
              Translating your file
            </div>
            <div className='relative text-lg leading-[22px] font-semibold'>
              Upload Progress: {uploadProgress}%
            </div>
          </div>
        )}
        {!isTranscribing && (
          <div className='flex gap-20 min-h-screen px-36 py-10'>
            <div className='gap-4 [filter:drop-shadow(0px_3px_5.5px_rgba(140,_140,_140,_0.25))] rounded-xl flex flex-col items-center justify-center px-40 text-[24px] border-[1px] border-solid border-updated-color-grey1 h-fit py-20'>
              <div className='flex flex-col items-center justify-start gap-[25px] mb-4'>
                <img className='w-fit h-fit' alt='' src='/file-translate.svg' />
                <div className='flex flex-col items-center justify-start gap-[10px]'>
                  <div className='relative leading-[22px] font-semibold'>
                    Translate Document
                  </div>
                  <div className='w-[420px] relative text-base leading-[22px] text-center inline-block'>
                    All document types are supported.
                    <br />
                    <br /> .PDF .DOCX .XLSX .PPTX .EPUB .PO .SRT .TXT .HTML .ZIP
                    .XML .XLF .GO .JPG .JPEG .PNG .MD .ODT .ODS .ODP .CSV .TSV
                    .YAML .PHP .AI
                  </div>
                </div>
              </div>
              <div className='flex text-center text-sm cursor-pointer relative'>
                <input
                  type='file'
                  accept='.pdf, .docx, .xlsx, .pptx, .epub, .po, .srt, .txt, .html, .zip, .xml, .xlf, .go, .jpg, .jpeg, .png, .md, .odt, .ods, .odp, .csv, .tsv, .yaml, .php, .ai'
                  ref={fileInputRef}
                  className='absolute opacity-0 w-full h-full z-10 cursor-pointer'
                />
                <div className='flex flex-row items-center justify-center'>
                  <div className='rounded-tl-23xl rounded-tr-none rounded-br-none rounded-bl-23xl bg-white flex flex-row items-center justify-center py-0.5 px-[15px] border-[0.5px] border-solid border-updated-color-grey1'>
                    <div className='relative leading-[22px]'>
                      {uploadedFileName ? uploadedFileName : 'Select a file'}
                    </div>
                  </div>
                  <div className='rounded-tl-none rounded-tr-22xl rounded-br-22xl rounded-bl-none bg-white flex flex-row items-center justify-center py-1 px-[5px] border-[0.5px] border-solid border-updated-color-grey1'>
                    <img
                      className='w-[18px] relative h-[18px] overflow-hidden shrink-0'
                      alt=''
                      src='/arrow--arrow-down-2.svg'
                    />
                  </div>
                </div>
              </div>
              <div
                className='flex text-center text-sm cursor-pointer relative'
                onClick={() => {
                  setIsLanguageSelectOpen(true);
                }}
              >
                <div className='flex flex-row items-center justify-center'>
                  <div className='rounded-tl-23xl rounded-tr-none rounded-br-none rounded-bl-23xl bg-white flex flex-row items-center justify-center py-0.5 px-[15px] border-[0.5px] border-solid border-updated-color-grey1'>
                    <div className='relative leading-[22px]'>
                      {outputLanguage || 'Select a language'}
                    </div>
                  </div>
                  <div className='rounded-tl-none rounded-tr-22xl rounded-br-22xl rounded-bl-none bg-white flex flex-row items-center justify-center py-1 px-[5px] border-[0.5px] border-solid border-updated-color-grey1'>
                    <img
                      className='w-[18px] relative h-[18px] overflow-hidden shrink-0'
                      alt=''
                      src='/arrow--arrow-down-2.svg'
                    />
                  </div>
                </div>
              </div>
              <div
                className='rounded-3xs [background:linear-gradient(rgba(0,_126,_243,_0.3),_rgba(0,_126,_243,_0.3)),_#fff] flex items-center justify-center py-[5px] px-[15px] border-[0.5px] border-solid border-updated-color-grey1 cursor-pointer'
                onClick={() => {
                  if (userInfoRef.current?.credits <= 10) {
                    setIsOutOfCreditsOpen(true);
                    return;
                  }
                  uploadFile(fileInputRef.current.files[0]);
                }}
              >
                <div className='relative font-medium text-base'>Translate</div>
              </div>
            </div>
            <div className='flex flex-col w-80 gap-4'>
              <div className='flex flex-col justify-center relative w-full gap-1'>
                <img
                  src='/cam.svg'
                  alt=''
                  className='w-6 h-6 absolute top-2 left-2'
                />
                <input
                  placeholder='Paste Meeting URL or Video Link'
                  className='text-base pl-8 py-2.5 border-solid rounded-full border border-updated-color-grey1 focus:outline-none bg-transparent shadow'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addBotToMeeting(e.target.value);
                    }
                  }}
                />
                <div className='flex items-center text-[#c5c5c5] font-bold self-end gap-1 text-base'>
                  Supports
                  <img
                    src='/platforms.svg'
                    alt='Meeting platform logos'
                    className='w-20'
                  />
                </div>
              </div>
              <RecentsSidebar />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileTranslate;
